export default 
{
  title: "Welcome on my website",
  desc: `You can find on this website:`,
  software: "My software",
  software_desc: "My own applications developped mainly with C++/Qt",
  rubik: "How to solve a Rubik's Cube?",
  rubik_desc: "Learn how to solve Rubik's Cube according to your level",
  piano: "Piano",
  piano_desc: "Learn solfeggio",


  menu : {
    home : "Home",
    software: "My software",
    rubik: "Rubik's Cube",
    piano: "Piano",
    links: "Links",
    about: "About..."
  },

  form: {
    text: `Any improvement idea ?
    Any bug ?
    Any encouragement ?`,
    send: 'Send',
    placeholder: 'Your message'
  }
};
  