export default 
{
    title: "Developer C++/Qt",

    experience: "Professional Experience",
    skills: "Skills",
    languages: "Languages",
    formation: 
    {
        title: 'Formation',
        engineer : 'Engineering Degree',
        bac: 'High school diploma',
    },



    contact: "Contact",

    db: "Database",

    techEnv: "Technical env.",

    lang: {
        french: "French",
        english: "English",
    },

    jobs: {
        forsk: {
            missions: 
                [
                    {
                        poste: 'C++ Developer',
                        desc: `Solving bugs...`,
                        env: 'C++, C#, Python, Oracle, SQL Server, PostgreSQL',
                    },
            ]
        },
        csgroup: {
            missions: 
                [
                    {
                        poste: ' C++/Qt, React Developer',
                        desc: `C/C++ Development of an offline protection library using software licenses for Windows/Linux for Airbus
                            Development of a web portal for license management/generation
                            Integration of the library into Airbus products
                            Python development of task automation tools
                            Awareness of security issues, cryptography`,
                        env: 'C++, Qt, React, Git',
                    },
                    {
                        poste: 'C++ / Android / Unreal Engine Developer',
                        desc: `Development of an augmented reality remote assistance application. The application allows communication (voice, video, messaging) between an Expert and an Operator while providing 3D annotation tools to position markers (arrows, line drawings, etc.) in the Operator's 3D virtual environment.
                            C++ development of the Windows PC Expert application.
                            Android/QML development of the application for the Operator with activation of Android ARCore to reconstruct the 3D environment using the depth map.
                            Porting the Android solution to Unreal Engine 4 for HoloLens2 headsets by reconstructing the 3D environment using the headset's spatial mapping.`,
                        env: 'C++, Android, QML, Unreal Engine, Git',
                    },
                ]
        },
        evs: {
            missions: 
                [
                    {
                        poste: 'C++ Developer' ,
                        desc: `EVS is a company specialized in the design of video servers for image professionals, including tools for managing slow-motion replays for major sporting events.
                            Development of the software for encoding and extracting video streams into an MXF container, used in professional environments`,
                        env: 'C++, Pyton, Git, Confluence, Jenkins',
                    },
            ]
        },
        ausy: {
            missions: 
                [
                    {
                        poste: 'Java Developer',
                        desc: `Java development of a virtualized WAP communication solution between the embedded tablets through the deployment of a Wi-Fi access point on a Debian virtual machine in the aircraft cockpit.`,
                        env: 'Java, Debian, Apache Ant, Tortoise SVN, JIRA, Jenkins, Linux, Eclipse',
                    },
            ]
        },
        amadeus: {
            missions: 
                [
                    {
                        poste: 'C++ / UNIX / Oracle Developer',
                        desc: `As part of an international team, I am responsible for the replication of common reference data for the company's applications.
                            C++ development under UNIX of a distributed server/client architecture for publishing, modifying, and distributing files, communicating via Edifact messages and subject to strict non-concurrent access constraints in an Oracle database.
                            Python development of log extraction tools and task automation.
                            Extension of the framework to a scalable, cascaded network of file distribution daemons, relieving the Fail-Over node.
                            Migration of the framework to a Docker container solution deployed in Amazon Web Services, communicating with a NoSQL Couchbase database.
                            Continuous integration with Jenkins, unit tests, code reviews, production deployment.
                            Work using Agile/Scrum methodology with 3-4 week sprints.`,
                        env: 'C++, UNIX, Python, Databsases Oracle/SQLite, Docker, Couchbase, Edifact, Eclipse, Version control system Mercurial, Confluence, JIRA, continuous integration Jenkins, Agile',
                    },
                    {
                        poste: 'Web Java EE Developer',
                        desc: `Development of the client connection module for the websites provided by Amadeus for flight or hotel booking services, as well as the internal connection module for Amadeus with rights and privileges management, subject to strict security and optimization requirements.`,
                        env: 'Java EE, HTML, CSS, JavaScript, aria-templates, Maven, WebServices SOAP, Microsoft SQL Server, SSL, Version control system Clearcase, Jenkins',
                    },
                    {
                        poste: 'C++ / UNIX / Oracle Developer',
                        desc: `Developer within the Amadeus Ticketing team providing a solution for the issuance and printing of flight tickets, whether virtual or physical, based on the passenger’s PNR data.
                            TPF deco: migration from the Legacy platform to a C++ solution under UNIX.
                            Implementation of a Guaranteed Delivery: daemon ensuring the cancellation of a corrupted issued ticket.
                            Strict response time optimization requirements in an environment subjected to high query traffic (~ 20 transactions/sec).`,
                        env: 'C++, UNIX, Python, Databases Oracle/SQLite',
                    },
            ]
        },
    }
};
  