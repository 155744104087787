import {React, useMemo} from "react";

import { Container, Typography, Box, Link, Grid, Button } from '@mui/material';


import { BannerSoftware } from "Software/bannerSoftware";



import { useTranslation  } from 'react-i18next';


function Banner ({title1="Hello", title2=null, title3=null, title4=null, image=null, halo=true, page=null, youtube=false, mb=3}) {


	const { t, i18n } = useTranslation("home");

	const tRubikLayer = useTranslation("methodLayer");
	const tRubikFridrich = useTranslation("methodFridrich");

	return  <Box
		sx={{
			width: '100%', // Remplit toute la largeur
			background: 'linear-gradient(to right, #000848, #0008B8)', // Dégradé de gauche à droite
			color: 'white', // Couleur du texte
			padding: 2, // Padding (ajuste si nécessaire)
			position: 'relative', // Assure que le conteneur est positionné correctement
			left: 0, // Assure que le conteneur commence à gauche
			mb: mb
		}}
	>
		<Container>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={8} md={8} lg={8}>
					<Typography variant="h1" sx={{mb: 3}}>{title1}</Typography>
					{title2 && <Typography variant="h2">{title2}</Typography>}

					{page=="about" && 
						<Box>
							<Box display="flex" sx={{m: 3}}>
								<img src="/res/about/c++.png" width="5%" title="C++" />
								<img src="/res/links/qt.png" width="5%" title="Qt" />
								<img src="/res/links/python.png" width="5%" title="Python" />
								<img src="/res/about/react.png" width="5%" title="React"  />
		
							</Box>
						</Box>}

					{title3 && <Typography variant="h3">{title3}</Typography>}
					{title4 && <Typography variant="h4">{title4}</Typography>}

					{page=="home" && <Box display="flex">
						<Button variant="contained" sx={{ m: 1 }} href="/software">{t('menu.software')}</Button>
						<Button variant="contained" sx={{ m: 1 }} href="/rubik">Rubik's Cube</Button>
						<Button variant="contained" sx={{ m: 1 }} href="/piano">Piano</Button>
					</Box>}

				


					{page=="software" && 
						<Box>
							<BannerSoftware />
						</Box>}

						
					{page=="rubik" && 
						<Box>
							<Box display="flex">
								<Button variant="contained" sx={{ m: 1 }} href="/methodLayer">{tRubikLayer.t('title')}</Button>
								<Button variant="contained" sx={{ m: 1 }} href="/methodFridrich">{tRubikFridrich.t('title')}</Button>
							</Box>
						</Box>}

		

					{youtube && <Link href="https://www.youtube.com/@sebLabo/videos" sx={{ m: 1 }}><img src="/youtube.png" width="10%" /></Link>}
				</Grid>

				<Grid item xs={12} sm={4} md={4} lg={4}>
					{image && <img 
						src={image} 
						className="responsive-image2"
						style={{  
							borderRadius:'20px' , maxHeight: '200px'}}
					/>}

{page == "home" &&  				<iframe src='https://my.spline.design/topspinning-793e63d8a128379adb6277c326ff9304/' frameborder='0' width='100%' height='100%'></iframe>}
{page == "rubik" &&  				<iframe src='https://my.spline.design/untitled-7f25e4a199ffbc79a8b275bb203d7835/' frameborder='0' width='100%' height='100%'></iframe>}
				</Grid>
			</Grid>
		</Container>
  </Box>;
};

export default Banner;