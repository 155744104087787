import React from "react";

import Menu from "menu";

import { Container, Typography, Grid2, Grid, Paper, Box , Button, LinearProgress, Avatar, Link, Stack } from '@mui/material';
import { BannerFridrich } from "./bannerFridrich";

import { useTranslation } from 'react-i18next';

import Banner from "banner";
import { Paragraph } from "toolbox";

export default (() => {

    const { t, i18n } = useTranslation("methodFridrich");


	return <Box>


        <Menu idx={2}/>
        <Banner title1="Rubik's Cube"  title2={t('title')} image="/res/rubik/fridrich/rubik_piano.jpg" page="rubikFridrich" mb={0}/>


        <BannerFridrich idx={0} t={t}/>


		<Container>
			
            <Grid container spacing={2} sx={{ mt:2 }}>
                <Grid item xs={4} md={8}>
                    <Typography variant="h1_rubik">{t('title')}</Typography>
                </Grid>
               
            </Grid>
        

            <Paragraph text='header' t={t} />

            <Paragraph text='header2' t={t} />
            <ul>
                <li><a href="#croix">{t('title1')}</a></li>
                <li><a href="#f2l">{t('title2')}</a></li>
                <li><a href="#oll">{t('title3')}</a></li>
                <li><a href="#pll">{t('title4')}</a></li>
            </ul>
   
            <h2 id="croix">{"1) "+t('title1')}</h2>
            <Paragraph text='step1' t={t} />
























            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, sm: 6, md: 8, lg: 9 }}>
                    <h3>{t('faster')}</h3>
                        <Paragraph text='step1b' t={t} />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <img src="/res/rubik/fridrich/F_01.png" alt="Cube" />
                </Grid2>
            </Grid2>

            <Paragraph text='step2' t={t} />
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, sm: 6, md: 8, lg: 9 }}>
                <h3>{t('faster')}</h3>
                <Paragraph text='step2b' t={t} />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <img src="/res/rubik/fridrich/F_02.png" alt="Cube" />
                </Grid2>
            </Grid2>

            <h2 id="oll">{"3) "+t('title3')}</h2>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, sm: 6, md: 8, lg: 9 }}>
                <Paragraph text='step3' t={t} />
                    <a href="/methodFridrichOLL" >{t('linkAlgos')}</a>
                    
                    <h3>{t('faster')}</h3>
                    <Paragraph text='step3b' t={t} />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <img src="/res/rubik/fridrich/F_03.png" alt="Cube" />
                </Grid2>
            </Grid2>

            <h2 id="pll">{"4) "+t('title4')}</h2>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, sm: 6, md: 8, lg: 9 }}>
                <Paragraph text='step4' t={t} />
                <a href="/methodFridrichPLL" >{t('linkAlgos')}</a>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <img src="/res/rubik/fridrich/F_04.png" alt="Cube" />
                </Grid2>
            </Grid2>








		</Container>
	</Box>
});
