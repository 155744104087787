export default 
{
    title: "Résolution du Rubik's Cube",
    methods: "Méthodes de résolution",
  //  methodBeginner: "Méthode débutant",
   // layerDesc:"Une méthode de résolution facile avec peu d'algorithmes à apprendre",
  //  methodExpert:  "Méthode avancée",
  //  fridrichDesc: "Une des méthodes les plus rapides utilisée par la plupart des meilleurs speedcubers pour battre les records!",
   
    title2: "Apprendre à résoudre le Rubik's Cube 3x3x3",
    titleWR: "Evolution du Record du Monde",
    p001: `Voici quelques méthodes pour résoudre le Rubik's Cube à la main. 
    Si vous voulez un programme capable de résoudre un Rubik's Cube, consultez mon programme de résolution :`,
    numbers: "Quelques chiffres",
    invention: "invention par Erno Rubik",
    combinations: "de combinaisons différentes",
    moves: "nombre de mouvements max possible pour la résolution",
    wr: "Record mondial du temps de résolution",
    title2: "Un peu d'histoire avant tout",
    desc: `Inventé par Erno Rubik en 1974 qui mit lui-même plus d'un mois avant de résoudre le sien, le Rubik's Cube est un casse-tête mondialement réputé pour sa difficulté.
      En effet, il existe plus de 43 milliards des milliards de combinaisons différentes, dont 1 seule correspond au cube fini.
      Sachez cependant que quelle que soit le mélange du cube, celui-ci peut toujours être (théoriquement) résolu en 20 mouvements maximum.
      Pour ceux qui, du coup, sous-estimant la difficulté, voudraient essayer de le résoudre par eux-mêmes, en tournant les faces jusqu'à arriver à un cube résolu, sachez qu'il vous faudrait plus de 1200 ans pour tester toutes les combinaisons.. a raison de 1 milliard de combinaisons testées par seconde...!
      Envie de relever le défi ? Envie de résoudre enfin ce cube et reconstituer ses 6 faces d'une même et unique couleur ? (Oui, au fait, c'est le but du jeu !`,
 
};