export default 
{
  title: "Apprentissage du solfège",
  rules: `Apprenez le solfège pour le piano à travers ce petit jeu.
Le but est de retrouver le plus de notes possibles en 1 minute.`,
start: 'Démarrer',
levelChoice: "Choisissez un niveau d'apprentissage",

level1: 'Touches blanches / Clé de Do',
level2: '+ Touches noires',
level3: '+ Clé de Fa',
level4: '+ armures',
level5: 'Oreille absolue',
level99: 'Entrainement',

scales: "Gammes",
all: "Toutes",

};