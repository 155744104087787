export default 
{
    title: "Développeur C++/Qt",

    experience: "Expérience Professionnelle",
    skills: "Compétences",
    languages: "Langues",
    formation: 
    {
        title: 'Formation',
        engineer : 'Diplôme Ingénieur',
        bac: 'Baccalauréat S',
    },
    contact: "Contact",

    db: "Base de données",

    techEnv: "Env. technique",

    lang: {
        french: "Français",
        english: "Anglais",
    },
    

    jobs: {
        forsk: {
            missions: 
                [
                    {
                        poste: 'Développeur C++',
                        desc: 'Je résous des bugs...',
                        env: 'C++, C#, Python, Oracle, SQL Server, PostgreSQL',
                    },
            ]
        },
        csgroup: {
            missions: 
                [
                    {
                        poste: 'Développeur C++/Qt, React',
                        desc: `Développement C/C++ d'une librairie de protection hors-ligne par licence de logiciel Windows/Linux pour Airbus
                        Développement d'un portail Web de gestion / génération de licences
                        Intégration de la librairie dans les produits Airbus
                        Développement Python d'outils d'automatisation de tâches
                        Sensibilisation aux problèmes de sécurité, cryptographie`,

                        env: 'C++, Qt, React, Git',
                    },
                    {
                        poste: 'Développeur C++ / Android / Unreal Engine',
                        desc: `Développement d'une application de téléassistance en réalité augmentée. L'application consiste à pouvoir faire communiquer (voix, vidéo, messagerie) un Expert et un Opérateur tout en fournissant des outils d'annotations 3D pour positionner des marqueurs (flèches, tracés de lignes...) dans l'environnement virtuel 3D de l'opérateur
                                Développement C++ de l’application PC Windows Expert
                                Développement Android/QML de l’application pour l'opérateur avec activation de Android ARCore pour reconstruire l'environnement 3D en utilisant la carte de profondeur
                                Portage de la solution Android sous Unreal Engine 4 pour les casques HoloLens2 en reconstruisant l'environnement 3D grâce au spatial mapping du casque.`,
                        env: 'C++, Android, QML, Unreal Engine, Git',
                    },
                ]
        },
        evs: {
            missions: 
                [
                    {
                        poste: 'Développeur C++',
                        desc: `EVS est une société spécialisée dans la conception de serveurs vidéo destinés aux professionnels de l’image, notamment des outils gérant les ralentis pour les grands évènements sportifs
Développement du logiciel permettant l’encodage et l’extraction des flux vidéo dans un conteneur MXF, utilisé en milieu professionnel`,
                        env: 'C++, Pyton, Git, Confluence, Jenkins',
                    },
            ]
        },
        ausy: {
            missions: 
                [
                    {
                        poste: 'Développeur Java',
                        desc: `Développement Java d’une solution virtualisée de communication WAP entre les tablettes embarquées à travers le déploiement sur machine virtuelle Debian d’un point d’accès Wifi dans le cockpit de l’avion`,
                        env: 'Java, Debian, Apache Ant, Tortoise SVN, JIRA, Jenkins, Linux, Eclipse',
                    },
            ]
        },
        amadeus: {
            missions: 
                [
                    {
                        poste: 'Développeur C++ / UNIX / Oracle',
                        desc: `Au sein d’une équipe internationale, je suis en charge de la réplication des données de référence communes aux applications de l’entreprise
                            Développement C++ sous UNIX d’une architecture distribuée serveur/clients de publication, modification, distribution de fichiers, communiquant par messages Edifact et soumis à de fortes contraintes d’accès non concurrentiels dans une base de données Oracle
                            Développement Python d’outils d’extraction de logs et d’automatisation des tâches
                            Extension du framework à un réseau évolutif cascadé de daemons de distribution de fichiers, soulageant le nœud Fail-Over
                            Migration du framework à une solution de containers Docker déployés dans Amazon Web Services, communiquant avec une base de données NoSQL Couchbase
                            Intégration continue Jenkins, tests unitaires, codes reviews, mise en production
                            Travail en méthodologie Agile/Scrum avec des sprints de 3-4 semaines`,
                        env: 'C++, UNIX, Python, Bases De Données Oracle/SQLite, Docker, Couchbase, Edifact, Eclipse, gestionnaire de versions Mercurial, Confluence, suivi JIRA, intégration continue Jenkins, Agile',
                    },
                    {
                        poste: 'Développeur Web Java EE',
                        desc: `Développement du module de connexion client des sites Web délivrés par Amadeus pour des services de réservation de vols ou d’hôtels, ainsi que du module de connexion interne d’Amadeus avec gestion des droits et privilèges, soumis à de fortes contraintes de sécurité et d’optimisation`,
                        env: ' Java EE, HTML, CSS, JavaScript, aria-templates, Maven, WebServices SOAP, Microsoft SQL Server, SSL, gestionnaire de versions Clearcase, Jenkins',
                    },
                    {
                        poste: 'Développeur C++ / UNIX / Oracle',
                        desc: `Développeur au sein de l’équipe Ticketing d’Amadeus fournissant une solution d’émission, impression des billets d’avion, qu’ils soient virtuels ou physiques, à partir des données du PNR du passager.
                            TPF deco : migration de la plateforme Legacy à une solution C++ sous UNIX
                            Mise en place d’une Guaranteed Delivery : daemon garantissant l’annulation d’un ticket émis corrompu
                            Fortes contraintes d’optimisation du temps de réponse dans un environnement soumis à un fort trafic de requêtes (~ 20 transactions / sec)`,
                        env: 'C++, UNIX, Python, Bases De Données Oracle/SQLite',
                    },
            ]
        },
    }
};