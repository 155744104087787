import React, { useEffect, useState } from 'react';
import './MenuAuto.css'

const MenuAuto = ({ content, titles='h1, h2, h3, h4, h5, h6' }) => {
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    // Extrait les titres (balises h1, h2, h3, etc.) de l'élément "content"
    const headingElements = content.querySelectorAll(titles);
    const headingsArray = Array.from(headingElements).map(heading => ({
      id: heading.id || heading.textContent.trim().toLowerCase().replace(/\s+/g, '-'), // Id unique pour chaque titre
      text: heading.textContent,
      level: parseInt(heading.tagName.replace('H', ''), 10), // Niveau de titre (1 pour h1, 2 pour h2, etc.)
    }));
    setHeadings(headingsArray);
  }, [content]);

  return (
    <div class="menuA">
      <ul>
        {headings.map((heading) => (
          <li key={heading.id}>
            <a href={`#${heading.id}`}>{heading.text}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuAuto;
