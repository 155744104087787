export default 
{
    title: "How to solve a Rubik's Cube",
    methods: "Solving methods",
    methodBeginner: "Beginner method",
    layerDesc: "A simple solving method with few algorithms to learn",
    methodExpert:  "Expert method",
    fridrichDesc: "One of the fastest methods used by most of the top speedcubers to break records!",
    title2: "Learn how to solve the 3x3x3 Rubik's Cube",
    titleWR: "World Record timeline",
    p001: `Here are some methods to solve the Rubik's Cube by hand. 
    If you're looking for a program that can solve a Rubik's Cube, check out my solver program:`,
    numbers: "Some figures",
    invention: "invention by Erno Rubik",
    combinations: "different combinations",
    moves: "maximum number of possible moves for the solution",
    wr: "solving time World Record",
    title2: "A bit of history before anything else",
    desc: `Invented by Erno Rubik in 1974, who himself took more than a month to solve his own, the Rubik's Cube is a puzzle globally renowned for its difficulty.
    Indeed, there are over 43 quintillion different combinations, with only one corresponding to the solved cube. However, know that no matter how the cube is scrambled, it can always (theoretically) be solved in a maximum of 20 moves.\nFor those who, underestimating the challenge, would like to try solving it themselves by rotating the faces until reaching a solved cube, be aware that it would take you more than 1,200 years to test all the combinations... at a rate of 1 billion combinations tested per second...!\nFeeling up for the challenge? Want to finally solve this cube and reconstruct its six faces into a single, uniform color? (Yes, by the way, that’s the goal of the game!)`,

};
  