import React, { useEffect, useRef, useState } from "react";

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Grid, Grid2, Paper, Box , ButtonGroup, Button, LinearProgress, Avatar, Link, Stack, Item, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Checkbox } from '@mui/material';

import { useTranslation } from 'react-i18next';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';


import  Vex, {  Stave, StaveNote, Formatter } from "vexflow";

import './piano.css';

import * as Tone from "tone";











const Canvas = (props) => {
    return <canvas ref={props.canvasref}  {...props} />;
  };


const MyStave = ({note, clef, keySignature, level, accidentals, displayNote, height}) => {
    const elementRef = useRef();
  
    function getNotesObjectFromProps(VF) {

let note_ = new VF.StaveNote({ clef: clef, keys: [note+"/"+height], duration: "q" });
if (note.length == 2  && accidentals == 1)
{
  if (note[1] == '#')
    note_.addModifier(new VF.Accidental("#"), 0);
  else
    note_.addModifier(new VF.Accidental("b"), 0);
}

      return [ note_];
    }


    useEffect(() => {
      const divElement = elementRef.current;
  
      const VF = Vex.Flow;
  
      var WorkspaceInformation = {
        canvas: divElement,
        canvasWidth: 200,
        canvasHeight: 200
      };
  
      var renderer = new VF.Renderer(
        WorkspaceInformation.canvas,
        VF.Renderer.Backends.CANVAS
      );
  
      renderer.resize(
        WorkspaceInformation.canvasWidth,
        WorkspaceInformation.canvasHeight
      );
  
      var context = renderer.getContext();
  
      context.setFont("Arial", 10, "").setBackgroundFillStyle("#eed");
  
      var stave = new VF.Stave(10, 40, 400);
      stave.addClef(clef)
      if (accidentals == 2 /*&& displayNote*/)
        stave.addKeySignature(keySignature);
      stave.setContext(context).draw();
  
      var notes = getNotesObjectFromProps(VF);
      console.log(notes)
  
      var voice = new VF.Voice({ num_beats: 4, beat_value: 4 });
      voice.setStrict(false);
      voice.addTickables(notes);
  
      var formatter = new VF.Formatter().joinVoices([voice]).format([voice], 400);
  
     // if (displayNote)
        voice.draw(context, stave);

      
      
    });

    return <Canvas height={5}  canvasref={elementRef} />;

  }
  





export default (() => {

	const { t, i18n } = useTranslation("piano");


  const LEVELS = [
    {
      level: 1, 
      title: t('level1'),
      keys : ['C'],
      sound: true,
      scales : ['C'],
      heights: [4, 5],
      accidentals: 1,
      allKeysActive: false,
      displayNote: true
    },
  
    {
      level: 2, 
      title: t('level2'),
      keys : ['C'],
      sound: true,
      scales : ['C', 'G', 'D', 'A', 'E', 'B', 'F', 'Bb', 'Eb', 'Ab', 'Db'],
      heights: [4],
      accidentals: 1,
      allKeysActive: false,
      displayNote: true
    },
  
    {
      level: 3, 
      title: t('level3'),
      keys : ['C', 'D'],
      sound: true,
      scales : ['C', 'G', 'D', 'A', 'E', 'B', 'F', 'Bb', 'Eb', 'Ab', 'Db'],
      heights: [4],
      accidentals: 1,
      allKeysActive: false,
      displayNote: true
    },
  
    {
      level: 4, 
      title: t('level4'),
      keys : ['C', 'D'],
      sound: true,
      scales : '*',
      heights: [4],
      accidentals: 2,
      allKeysActive: false,
      displayNote: true
    },
  
    {
      level: 5, 
      title: t('level5'),
      keys : ['C', 'D'],
      sound: true,
      scales : '*',
      heights: [4],
      accidentals: 2,
      allKeysActive: false,
      displayNote: false
    },

  ]
  
  

  


    const [note, setNote] = useState(null);
    const [clef, setClef] = useState(null);
    const [height, setHeight] = useState(null);
    const [keySignature, setKeySignature] = useState(null);
    
    const [erreur, setErreur] = useState('');

    const [score, setScore] = useState(0);

    const [goods, setGoods] = useState(0);
    const [errors, setErrors] = useState(0);

    const [timeLeft, setTimeLeft] = useState(60);

    const [level, setLevel] = useState(1);
    const [keys,  setKeys] = useState(['C']);
    const [sound,  setSound] = useState(true);
    const [scales, setScales] = useState(['*']);
    const [accidentals, setAccidentals] = useState(0);
    const [heights, setHeights]= useState([3]);
    const [visibleNote,  setVisibleNote] = useState(true);

    const [enabledForm, setEnabledForm] = useState(false);

    const [notes, setNotes] = useState([]);

    const [playStatus, setPlayStatus] = useState(0);


    const SCALES = new Map();
    SCALES.set('C', ['c', 'd', 'e', 'f', 'g', 'a', 'b']);

    SCALES.set('G', ['g', 'a', 'b', 'c', 'd', 'e', 'f#']);
    SCALES.set('D', ['d', 'e', 'f#', 'g', 'a', 'b', 'c#']);
    SCALES.set('A', ['a', 'b', 'c#', 'd', 'e', 'f#', 'g#' ]);
    SCALES.set('E', ['e', 'f#', 'g#', 'a', 'b', 'c#', 'd#']);
    SCALES.set('B', ['b', 'c#', 'd#', 'e', 'f#', 'g#', 'a#']);
    SCALES.set('F#', [ 'f#', 'g#', 'a#', 'b', 'c#', 'd#', 'e#']);  
    SCALES.set('C#', ['c#', 'd#', 'e#', 'f#', 'g#', 'a#', 'b#']);

    SCALES.set('F', ['f', 'g', 'a', 'bb', 'c', 'd', 'e']); 
    SCALES.set('Bb', ['bb', 'c', 'd', 'eb', 'f', 'g', 'a']); 
    SCALES.set('Eb', ['eb', 'f', 'g', 'ab', 'bb', 'c', 'd']); 
    SCALES.set('Ab', ['ab', 'bb', 'c', 'db', 'eb', 'f', 'g']); 
    SCALES.set('Db', ['db', 'eb', 'f', 'gb', 'ab', 'bb', 'c']); 
    SCALES.set('Gb', ['gb', 'ab', 'bb', 'cb', 'db', 'eb', 'f']); 
    SCALES.set('Cb', ['cb', 'db', 'eb', 'fb', 'gb', 'ab', 'bb']); 


  useEffect(() => {

    setEnabledForm(level == 99 && playStatus != 1);

    if (level == 99)
      return;
    const lev = LEVELS[level-1];

    let scales_ = lev.scales;
    console.log(scales_);
    if (scales_[0] == "*")
      scales_ = [...SCALES.keys()];
    console.log(scales_)
    scales_.forEach(s => console.log(SCALES.get(s)))
    setScales(scales_);

    let init_notes = [];
    let notes_ = [...new Set(scales_.reduce((acc, s) => [...acc, ...SCALES.get(s)], init_notes))];
    console.log(notes_);
    setNotes(notes_);

    setHeights(lev.heights);


    console.log(lev.keys)
    setKeys(lev.keys);
    setSound(lev.sound);

    setAccidentals(lev.accidentals);

    setVisibleNote(lev.displayNote);

    console.log(notes);

  }, [level, playStatus])


  const CountdownTimer = () => {

    useEffect(() => {
      if (timeLeft <= 0) return; 
      
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
  
      return () => clearInterval(timer);
    }, [timeLeft]); 
  
    return (
      <div>
        <h2>Temps restant: {timeLeft} secondes</h2>
        {timeLeft === 0 && <p>Le temps est écoulé !</p>}
      </div>
    );
  };
  


  // Fonction pour choisir un élément aléatoire
  const nextNote = () => {

    console.log(scales)
    const keySign_ = scales[Math.floor(Math.random() * scales.length)];
    console.log(keySign_)
    setKeySignature(keySign_);


    console.log(notes)
    console.log(level)
    let noteTmp = null;
    let notes_ = SCALES.get(keySign_);
   // console.log(note)
    do {
      noteTmp = notes_[Math.floor(Math.random() * notes_.length)];
    } while (note == noteTmp);
    //console.log(keySign)
    setNote(noteTmp);

    let key_ = keys[Math.floor(Math.random() * keys.length)];
    setClef(key_ == 'C' ? 'treble' : 'bass'); 

    const height_ = heights[Math.floor(Math.random() * heights.length)];
    setHeight(height_);

 //   setHeight(heights[Math.floor(Math.random() * heights.length)]);
    
  };

  const addGood = () => {
    setGoods(goods+1);
    setScore(score+1);
    setErreur('OK');
}

const addError = () => {
    setErrors(errors+1);
    setScore(score-1);
    setErreur('ERREUR!');
}



const Keyboard = ({note}) => {
  const keys = ['C/B#', 'C#/Db', 'D', 'D#/Eb', 'E/Fb', 'F/E#', 'F#/Gb', 'G', 'G#/Ab', 'A', 'A#/Bb', 'B/Cb'];
  const keyTouchs = [];
  const divRefs = useRef({});

  const Key = ({color, keyNote}) => {
    const [status, setStatus] = useState(0);
    const names = keyNote.toUpperCase().split('/');

    const checkNote = (keyNote) => {
      let ok = names.includes(note.toUpperCase());
      const div = divRefs.current[names[0]];
      console.log( div)
      if (div) {
        setStatus( ok ? 1 : 2);
        if (ok)
        {
          addGood();
          nextNote();
        }
        else
        {
           addError();
        }
      }
  }
  
    const handleClick= (key, index) => {
      console.log(key);
      //const synth = new Tone.Synth().toDestination();
      const synth = new Tone.Synth().toDestination();
      synth.triggerAttackRelease(key+"4", "8n");
      if (playStatus != 1)
        return;
      checkNote(key);
    }

    useEffect(() => {
      if (status != 0)
       setTimeout( () => setStatus(0), 200);
    }, [status]);



    useEffect(() => {
     // setScale(level == 99);
    }, [keys]);

    return  <div key={names[0]} class={color == "black" ? "black--key" : "key"} id={names[0].replace('#', 's')} ref={(el) => (divRefs.current[names[0]] = el)}  onClick={() => handleClick(names[0], 0)}> {""/*names[0]*/}</div>
  }


  
  // Fonction pour créer les touches noires à positionner entre certaines touches blanches
  const renderKeys = () => {
    let keyElements = [];
    keys.forEach((key, index) => {
      if (key.split('/')[0].includes('#')) {

      } else {
        keyTouchs.push(<Key color={"white"} keyNote={key} /> );
      }
    });


    keys.forEach((key, index) => {
      if (key.split('/')[0].includes('#')) {
        keyTouchs.push(<Key color={"black"} keyNote={key} />);
      } 
    });

    return keyTouchs;
  }


  return    <div id="piano"><div class="keys">{renderKeys()}</div></div>;  
  
};


  
    const startGame = () => {
      setScore(0);
      nextNote();
        setPlayStatus(1);
        setTimeLeft(60);
        setGoods(0);
        setErrors(0);
        setErreur('');
    }

    const endGame = () => {
      //setScore(0);
      //nextNote();
        setPlayStatus(0);
     //   setTimeLeft(60);
    }


    useEffect(() => {

      if (timeLeft <= 0)
        setPlayStatus(2);
    }, [timeLeft])

    const getScales = (s) => {
      if (s == '*')
      {
          s = SCALES.keys();
          return [...s];
      }
      else
        return s;
    }

    const handleLevelChange = (level_) => {
      //const level_ = event.target.value;
      setLevel(level_);
     
    };

    const handleKeysChange = (event) => {
      const { name, checked } = event.target;
      let key_ = event.target.value;

        if (checked)
          setKeys([...new Set([...keys, key_])]);
        else
        setKeys(keys.filter(s => s != key_));
    };

    const handleSoundChange = (event) => {
      console.log(event.target.value)
      setSound( event.target.value);
    };

    const handleScalesChange = (event) => {
      console.log(event.target)
      const { name, checked } = event.target;
      let scales_ = event.target.value;
      if (scales_ == '*')
      {
        if (checked)
        {
          scales_ = SCALES.keys();
          setScales([...scales_]);
        }
        else
        {
          setScales([]);
        }
      }
      else
      {
        if (checked)
          setScales([...new Set([...scales, scales_])]);
        else
          setScales(scales.filter(s => s !== scales_));
      }
    };

   

    const handleAccidentalsChange = (event) => {
      console.log(event.target.value)
      setAccidentals( event.target.value);
    };


    const handleVisibleNoteChange = (event) => {
      console.log(event.target.value)
      setVisibleNote( event.target.value);
    };

    const handleHeightsChange = (event) => {
      const { name, checked } = event.target;
      let height_ = event.target.value;

        if (checked)
          setHeights([...new Set([...heights, parseInt(height_)])]);
        else
          setHeights(heights.filter(s => s != height_));
    };


    const playNote = () => {
      if (note)
      {
        const synth = new Tone.Synth().toDestination();
        synth.triggerAttackRelease(note+"4", "8n");
      }
    }

    const isStarted = () => 
      {
        return playStatus == 1;
      }

    useEffect(() => {
      playNote();
    }, [note])


    const isStartPossible = () => {
      return keys.length > 0 && scales.length > 0 && heights.length > 0;
    }

	return <Box>
		<Menu idx={3}/>
		<Banner title1="Piano" title2={t('title')}  page="piano"  image="/res/piano/piano2.png" />

		<Container>
      
    <Typography>{t('rules')}</Typography>

    <FormLabel component="legend">{t('levelChoice')}</FormLabel>
      <ButtonGroup disabled={isStarted()} variant="outlined" aria-label="Basic button group">
        {LEVELS.map((s, i) => <Button onClick={() => handleLevelChange(s.level)} variant={level != s.level ? "outlined" : "contained"}  color={i!=4? "primary": "secondary"}>{s.title}</Button> )}
        <Button onClick={() => handleLevelChange(99)} variant={level != 99 ? "outlined" : "contained"}  color={"warning"}>{t('level99')}</Button>
      </ButtonGroup>



    <Box marginTop={4} marginBottom={8}>
      <Grid2 container >
					<Grid2 item size={{ xs: 12, md: 6 }}>

            <Keyboard note={note}/>
           {/*note*/}
            <Typography 
              sx={{ 
                fontWeight: 'bold',  
                color: erreur == 'OK' ? 'green' : 'red' 
              }}
            >
              {erreur}
            </Typography>
					</Grid2>
					<Grid2 item size={{ xs: 12, md: 6 }} height={50} >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {playStatus != 1 && <Button
            disabled={!isStartPossible()}
              variant="contained"  // Vous pouvez changer le style du bouton (text, outlined, etc.)
              color="primary"      // Vous pouvez ajuster la couleur du bouton
              startIcon={<PlayArrowIcon />} 
                onClick={() => startGame()}
            >{t('start')}</Button>}

            {playStatus == 1 && <Button
              variant="outlined"  // Vous pouvez changer le style du bouton (text, outlined, etc.)
              color="primary"      // Vous pouvez ajuster la couleur du bouton
              startIcon={<StopIcon />} 
                onClick={() => endGame()}
            > Stop</Button>}
  { playStatus >= 1 && <Button
              variant="outlined"  // Vous pouvez changer le style du bouton (text, outlined, etc.)
              color="primary"      // Vous pouvez ajuster la couleur du bouton
              startIcon={<VolumeUpIcon />} 
                onClick={() => playNote()}
            > Jouer la note</Button>}

              {playStatus >= 1 && <div> SCORE : {score}   ({goods}/{errors})</div>}
          </Box>
          {playStatus >= 1 && <CountdownTimer initialTime={60}/>}
          {playStatus  == 1 && visibleNote 
          && <MyStave note={note} clef={clef} height={height} keySignature={keySignature} level={level} accidentals={accidentals} displayNote={visibleNote} />}
					</Grid2>
		    </Grid2>
      </Box>



    <FormControl component="fieldset">

       <FormLabel  component="legend">Clefs</FormLabel>
      <RadioGroup   row>
        <FormControlLabel disabled={!enabledForm} value="C"  control={<Checkbox checked={keys.includes("C")}  onChange={handleKeysChange}/>} label="C"/>
        <FormControlLabel disabled={!enabledForm} value="D"  control={<Checkbox  checked={keys.includes("D")} onChange={handleKeysChange} />} label="F"/>
      </RadioGroup>

{/*
      <FormLabel component="legend">Son</FormLabel>
      <RadioGroup onChange={handleSoundChange} row>
        <FormControlLabel disabled={false} value={true} control={<Radio  />} label="Yes" />
        <FormControlLabel disabled={false} value={false}  control={<Radio />} label="No" />
      </RadioGroup>*/}


      <FormLabel component="legend">{t('scales')}</FormLabel>
      <RadioGroup  row>
        <FormControlLabel disabled={!enabledForm} value={'*'} control={<Checkbox checked={scales.length == SCALES.size} onChange={handleScalesChange} />} label={t('all')}/>
        {[...SCALES.keys()].map(s => <FormControlLabel disabled={!enabledForm} key={s} value={s} control={<Checkbox onChange={handleScalesChange} checked={scales.includes(s)} />} label={s}/>   )}   
      </RadioGroup>


      <FormLabel component="legend">Altérations</FormLabel>
      <RadioGroup value={accidentals} onChange={handleAccidentalsChange} row>
        <FormControlLabel value={1} disabled={!enabledForm} control={<Radio />} label="sur la note" />
        <FormControlLabel value={2} disabled={!enabledForm} control={<Radio />} label="par armure" />
      </RadioGroup>

      
      <FormLabel component="legend">Octaves</FormLabel>
      <RadioGroup  row>
      <FormControlLabel value={3} disabled={!enabledForm} control={<Checkbox  checked={heights.includes(3)} onChange={handleHeightsChange}  />} label="3" />
        <FormControlLabel value={4} disabled={!enabledForm} control={<Checkbox checked={heights.includes(4)} onChange={handleHeightsChange}  />} label="4" />
        <FormControlLabel value={5} disabled={!enabledForm} control={<Checkbox  checked={heights.includes(5)} onChange={handleHeightsChange}  />} label="5" />
      </RadioGroup>


      <FormLabel component="legend">Note visible</FormLabel>
      <RadioGroup onChange={handleVisibleNoteChange} row>
        <FormControlLabel disabled={!enabledForm} checked={visibleNote} value={true} control={<Radio  />} label="Yes" />
        <FormControlLabel disabled={!enabledForm} checked={!visibleNote} value={false}  control={<Radio />} label="No" />
      </RadioGroup>

{/*}
      <FormLabel component="legend">Hauteurs</FormLabel>
      <RadioGroup value={level} onChange={handleLevelChange} row>
        <FormControlLabel control={<Checkbox />} label="3"/>
        <FormControlLabel control={<Checkbox checked={true} disabled={true}/>} label="4"/>
        <FormControlLabel control={<Checkbox />} label="5"/>
      </RadioGroup>*/}

      {/*<FormLabel component="legend">Notation</FormLabel>
      <RadioGroup value={level} onChange={handleLevelChange} row>
        <FormControlLabel value="level1" control={<Radio />} label="1 : A, B, C.." />
        <FormControlLabel value="level2" control={<Radio />} label="2 : Do, Ré, Mi..." />
      </RadioGroup>*/}

      {/*
      <FormLabel component="legend">Afficher les touches inactives</FormLabel>
      <RadioGroup value={level} onChange={handleLevelChange} row>
        <FormControlLabel value="level1" control={<Radio />} label="Oui" />
        <FormControlLabel value="level2" control={<Radio />} label="Non" />
      </RadioGroup>
*/}

    </FormControl>

		</Container>
	</Box>
});