import React from "react";
import { useState } from 'react'

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link, Stack } from '@mui/material';


import Form from "../../form"


import { BannerSoftware } from "Software/bannerSoftware";


import { Platform, Category, SoftwareHeader } from "../software";


import { useTranslation } from 'react-i18next';
import { BannerSoftwareHeader} from '../bannerSoftwareHeader'
import { Paragraph } from "toolbox";



export default (() => {

	const { t, i18n } = useTranslation("rubikTeacher");

	const tLayer = useTranslation("methodLayer");
	const tFridrich = useTranslation("methodFridrich");


	return <Box>
		<Menu idx={1}/>

		 <Box sx={{ background: 'linear-gradient(to right, #000848,  #0008B8)'}}>
			  <Container>
					<BannerSoftware selected="rubikTeacher" backgroundColor={true}/>
			  </Container>
			</Box>
		


		<BannerSoftwareHeader title="Rubik Teacher" subtitle={t('title2')} icon="/res/rubikTeacher/rubikTeacherIcon.png"  type={1} />

		<Container>
			<SoftwareHeader  program="/res/rubikTeacher/RubikTeacher_v3.1.exe" t={t} type={1}/>
		</Container>

		<Container>
			<Box display="flex"
				justifyContent="center" // Centre horizontalement
				alignItems="center" // Centre verticalement
				sx={{m: 5}}
			>
				<iframe width="720" height="480"
					src="https://www.youtube.com/embed/Ig7HRs3gBnk">
				</iframe>
			</Box>
		</Container>


		<Container>
			<Grid container spacing={2} mb={5}>
				<Grid item xs={12} sm={6} md={4} lg={4}  
					sx={{backgroundColor: "#fcf3cf", padding: '16px'}}>
					<img  src="/res/rubikTeacher/rt_edition.png" className="responsive-image" style={{  borderRadius:'10px' }}/>
				</Grid>
				<Grid item xs={12} sm={6} md={8} lg={8}  sx={{backgroundColor: "#fcf3cf"}}>
					<Typography variant="h1_software">{t('modeSolve')}</Typography>
					<Typography>{t('modeSolveDesc')}</Typography>
				</Grid>
			</Grid>

			<Grid container spacing={2}>

				<Grid item xs={12} sm={6} md={8} lg={8}  sx={{backgroundColor: "#d5f5e3"}}>
					<Typography variant="h1_software">{t('modeLearn')}</Typography>
					<Typography>{t('modeLearnDesc')}</Typography>
					<Paragraph text='modeLearnDesc2' t={t} />
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}  sx={{backgroundColor: "#d5f5e3", padding: '16px'}}>
					<img  src="/res/rubikTeacher/rt_learn.png" className="responsive-image" style={{  borderRadius:'10px' }}/>
				</Grid>
			</Grid>



			<div>
				<h2 class="text-primary">{t('title1')}</h2>
			</div>

			<ol>
				<li>
					<b>{t('title11')}</b>
					<Paragraph text='area11' t={t} />
				</li>
				<li>
					<b>{t('title12')}</b>
					<Paragraph text='area12' t={t} />
				</li>
				<li>
					<b>{t('title13')}</b>
					<Paragraph text='area13' t={t} />
				</li>
				<li>
					<b>{t('title14')}</b>
					<Paragraph text='area14' t={t} />
				</li>
				<li>
					<b>{t('title15')}</b>
				</li>
				<li>
					<b>{t('title16')}</b>
					<Paragraph text='area16' t={t} />
				</li>
				<li>
					<b>{t('title17')}</b>
					<Paragraph text='area17' t={t} />
				</li>
				<li>
					<b>{t('title18')}</b>
				</li>
			</ol>


				<h2 class="text-primary">{t('modeSolve')}</h2>
				<Paragraph text='p2' t={t} />
		
				<h2 class="text-primary">{t('modeLearn')}</h2>
				<Paragraph text='p3' t={t} />
			

		<Form title='[Rubik Teacher]'/>

		</Container>
	</Box>
});