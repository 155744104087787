import {React, useEffect, useState} from "react";
import Home from "home";
import Page404 from "page404";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import Software from "Software/software"
import Tasky from "Software/Tasky/tasky";
import RubikTeacher from "Software/RubikTeacher/rubikTeacher";
import VirtualStackmatTimer from "Software/VirtualStackmatTimer/virtualStackmatTimer"

import Rubik from "Rubik/rubik"
import MethodFridrich from "Rubik/MethodFridrich/methodFridrich";
import MethodFridrichPLL from "Rubik/MethodFridrich/methodFridrichPLL";
import MethodFridrichPLL_LH from "Rubik/MethodFridrich/methodFridrichPLL_LH";
import MethodFridrichOLL from "Rubik/MethodFridrich/methodFridrichOLL";
import MethodFridrichOLL_LH from "Rubik/MethodFridrich/methodFridrichOLL_LH";
import MethodLayer from "Rubik/MethodLayer/methodLayer";

import Piano from "Piano/piano"

import Links from "Links/links"

import About from "About/about"
import CV from "About/CV"

import { ThemeProvider } from 'styled-components';

import './App.css'

import i18n from 'i18next';
import { useTranslation, initReactI18next  } from 'react-i18next';




const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;





const lightTheme = {
  background: 'white',
  color: 'black',
  font:"Verdana",
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {

      color: 'red',
    },
    h2: {

      color: 'green',
    }
  }
};

const darkTheme = {
  background: '#555',
  color: 'white',
  font: "Verdana",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    font-family: ${({ theme }) => theme.font};
  }
`;





export default () => {

    const [isDarkTheme, setIsDarkTheme] = useState(false);

    i18n.use(initReactI18next).init({
      lng: localStorage.getItem('language') || 'fr',
      fallbackLng: 'fr',
      interpolation: {
        escapeValue: false
      }
      });

    return (


   
   
      
   <Router>
        <Switch>
        	<Route exact path='/' component={Home}/>
		

            <Route exact path='/software' component={Software}/>
                <Route  path='/tasky' component={Tasky}/>
                <Route exact path='/rubikTeacher' component={RubikTeacher}/>
                <Route exact path='/virtualStackmatTimer' component={VirtualStackmatTimer}/>

            <Route exact path='/rubik' component={Rubik}/>
                <Route exact path='/methodLayer' component={MethodLayer}/>
                <Route exact path='/methodFridrich' component={MethodFridrich}/>
                    <Route exact path='/methodFridrichPLL' component={MethodFridrichPLL}/>
                      <Route exact path='/methodFridrichPLL_LH' component={MethodFridrichPLL_LH}/>
                    <Route exact path='/methodFridrichOLL' component={MethodFridrichOLL}/>
                    <Route exact path='/methodFridrichOLL_LH' component={MethodFridrichOLL_LH}/>

            <Route exact path='/piano' component={Piano}/>

            <Route exact path='/links' component={Links}/>

            <Route exact path='/about' component={About}/>
            <Route exact path='/CV' component={CV}/>

			<Route component={Page404}/>
        </Switch>

    </Router>
 
    
);
}
