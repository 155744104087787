import React, { useState } from 'react';

import Menu from "menu";
import Banner from "banner";


import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Paragraph } from "toolbox";


  
import { PureComponent } from 'react';
import { AreaChart, Area  } from 'recharts';
import {
	ResponsiveContainer,
	Legend,
	Tooltip,
	LineChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Line,
	Customized,
	Cross,
  } from "recharts";



const flags = {
	'Australie' : "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
	"Chine": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
	'USA' : "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg",
	'France' : 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
	'Pologne' : 'https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg',
	'Japon' : "https://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Japan.svg",
	 'Pays-Bas' : "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
	 'République de Corée': "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg",
	 'Finlande': 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg',
	'Danemark': 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg',

}

const competitors = {
	"Feliks Zemdegs" : 'Australie',
	'Ruihang Xu (许瑞航)' : 'Chine',
	'Max Park' : 'USA',
	'Edouard Chambon' : 'France',
	'Tomasz Żołnowski' : 'Pologne',
	'Yumu Tabuchi (田渕雄夢)' : 'Japon',
	'Erik Akkersdijk' : 'Pays-Bas',
	'Yu Nakajima (中島悠)': 'Japon',
	'Yiheng Wang (王艺衡)': 'Chine',
	'JeongMin Yu (유정민)': 'République de Corée',
	'Anssi Vanhala': 'Finlande',
	'Shotaro Makisumi (牧角章太郎)': 'Japon',
	'Dan Knights': 'USA',
	'Jean Pons': 'France',
	'Collin Burns': 'USA',
	'Thibaut Jacquinot': 'France',
	'Toby Mao': 'USA',
	'Tymon Kolasiński': 'Pologne',
	'Lucas Etter': 'USA',
	'Yusheng Du (杜宇生)': 'Chine',
	'Mats Valk': 'Pays-Bas',
	'Shotaro Makisumi (牧角章太郎)': 'Japon',
	'Jess Bonde': 'Danemark',
	"Ron Van Bruchem": "Pays-Bas",

}


const data = [

	{
		date: '2003-08-23',
		average: 20.00,
		name: 'Dan Knights',
		competition: 'World Championship 2003',
	  },





	  {
		date: '2004-10-16',
		average: 14.52,
		name: 'Shotaro Makisumi (牧角章太郎)',
		competition: 'Caltech Fall 2004',
	  },
	  {
		date: '2006-03-18',
		average: 14.40,
		name: 'Anssi Vanhala',
		competition: 'SveKub 2006',
	  },
	  {
		date: '2006-03-18',
		average: 13.22,
		name: 'Anssi Vanhala',
		competition: 'SveKub 2006',
	  },
	  {
		date: '2007-01-07',
		average: 11.76,
		name: 'JeongMin Yu (유정민)',
		competition: 'KCRC 2007',
	  },
	  {
		date: '2008-02-23',
		average: 11.48,
		name: 'Edouard Chambon',
		competition: 'Murcia Open 2008',
	  },
	  {
		date: '2008-03-29',
		average: 11.33,
		name: 'Yu Nakajima (中島悠)',
		competition: 'Osaka Open 2008',
	  },
	  {
		date: '2008-05-05',
		average: 11.28,
		name: 'Yu Nakajima (中島悠)',
		competition: 'Kashiwa Open 2008',
	  },
	  {
		date: '2009-02-21',
		average: 11.11,
		name: 'Erik Akkersdijk',
		competition: 'Benelux Open 2009',
	  },
	  {
		date: '2009-03-28',
		average: 10.83,
		name: 'Yumu Tabuchi (田渕雄夢)',
		competition: 'Osaka Open 2009',
	  },
	  {
		date: '2009-04-04',
		average: 10.63,
		name: 'Tomasz Żołnowski',
		competition: 'Warsaw Open 2009',
	  },
	  {
		date: '2009-09-26',
		average: 10.07,
		name: 'Tomasz Żołnowski',
		competition: 'Polish Open 2009',
	  },
	  {
		date: '2010-01-30',
		average: 9.21,
		name: 'Feliks Zemdegs',
		competition: 'Melbourne Summer Open 2010',
	  },
	  {
		date: '2010-07-10',
		average: 8.52,
		name: 'Feliks Zemdegs',
		competition: 'New Zealand Champs 2010',
	  },
	  {
		date: '2010-11-13',
		average: 7.91,
		name: 'Feliks Zemdegs',
		competition: 'Melbourne Cube Day 2010',
	  },
	  {
		date: '2011-01-29',
		average: 7.87,
		name: 'Feliks Zemdegs',
		competition: 'Melbourne Summer 2011',
	  },
	  {
		date: '2011-06-25',
		average: 7.64,
		name: 'Feliks Zemdegs',
		competition: 'Melbourne Winter Open 2011',
	  },
	  {
		date: '2012-09-01',
		average: 7.53,
		name: 'Feliks Zemdegs',
		competition: 'Australian Nationals 2012',

	  },
	  {
		date: '2013-09-07',
		average: 7.49,
		name: 'Feliks Zemdegs',
		competition: 'Australian Nationals 2013',

	  },
	  {
		date: '2013-11-16',
		average: 6.54,
		name: 'Feliks Zemdegs',
		competition: 'Melbourne Cube Day 2013',

	  },
	  {
		date: '2016-06-09',
		average: 6.45,
		name: 'Feliks Zemdegs',
		competition: 'WLS Lato 2016',

	  },
	  {
		date: '2017-04-23',
		average: 6.39,
		name: 'Max Park',
		competition: 'OCSEF 2017',

	  },
	  {
		date: '2017-06-28',
		average: 5.97,
		name: 'Feliks Zemdegs',
		competition: 'LatAm Tour - Chia 2017',
	  },
	  {
		date: '2017-11-14',
		average: 5.80,
		name: 'Feliks Zemdegs',
		competition: 'Malaysia Cube Open 2017',
	  },
	  {
		date: '2019-04-06',
		average: 5.69,
		name: 'Feliks Zemdegs',
		competition: 'Koalafication Brisbane 2019',
	  },
	  {
		date: '2019-11-10',
		average: 5.53,
		name: 'Feliks Zemdegs',
		competition: 'Odd Day in Sydney 2019',
	  },
	  {
		date: '2021-06-05',
		average: 5.48,
		name: 'Ruihang Xu (许瑞航)',
		competition: 'Wuhan Open 2021',
	  },
	  {
		date: '2021-11-06',
		average: 5.32,
		name: 'Max Park',
		competition: 'Missoula 2021',
	  },
	  {
		date: '2021-12-18',
		average: 5.09,
		name: 'Tymon Kolasiński',
		competition: 'Cubers Eve Lubartów 2021',
	  },
	  {
		date: '2022-04-03',
		average: 5.08,
		name: 'Max Park',
		competition: 'Bay Area Speedcubin',
	  },
	  {
		date: '2022-07-30',
		average: 4.86,
		name: 'Tymon Kolasiński',
		competition: "Cube4fun Warsaw 2022",
	  },
	  {
		date: '2022-09-24',
		average: 4.86,
		name: 'Max Park',
		competition: "Marshall Cubing September 2022",
	  },
	  {
		date: '2023-03-10',
		average: 4.69,
		name: 'Yiheng Wang (王艺衡)',
		competition: "YJ KL 2023",
	  },
	  {
		date: '2023-06-19',
		average: 4.48,
		name: 'Yiheng Wang (王艺衡)',
		competition: "Mofunland Cruise Open 2023",
	  },
	  {
		date: '2024-07-05',
		average: 4.36,
		name: 'Yiheng Wang (王艺衡)',
		competition: "Philippine Championship 2024",
	  },
	  {
		date: '2024-08-25',
		average: 4.25,
		name: 'Yiheng Wang (王艺衡)',
		competition: "Deqing Small Cubes Summer 2024",
	  },
	  {
		date: '2024-09-21',
		average: 4.09,
		name: 'Yiheng Wang (王艺衡)',
		competition: "Xuzhou Autumn 2024",
	  },
	  {
		date: '2024-11-01',
		average: 4.09,
		name: 'Yiheng Wang (王艺衡)',
		competition: "WCA Asian Championship 2024",
	  },

	  {
		date: '2024-12-08',
		average: 4.05,
		name: 'Yiheng Wang (王艺衡)',
		competition: "Zhengzhou Open 2024",
	  },













	{
		date: '2003-08-23',
		single: 16.53,
		name: 'Jess Bonde',
		competition: "Championnat du Monde 2003",


	  },
	{
		date: '2004-04-03',
		single: 12.11,
		name: 'Shotaro Makisumi (牧角章太郎)',
		competition: "Caltech Winter championat 2004",


	  },
	{
		date: '2005-10-16',
		single: 11.75,
		name: 'Jean Pons',


	  },

	{
		date: '2006-08-06',
		single: 10.48,
		name: 'Toby Mao',


	  },


	{
		date: '2007-02-24',
		single: 10.36,
		name: 'Edouard Chambon',
		competiton: "Belgian Open 2007",


	  },





	{
		date: '2007-05-05',
		single: 9.86,
		name: 'Thibaut Jacquinot',
		competition: "Spanish Open 2007",


	  },




	{
		date: '2007-10-14',
		single: 9.77,
		name: 'Erik Akkersdijk',
		competition: "Dutch Open 2007",


	  },
	{
		date: '2007-11-24',
		single: 9.55,
		name: 'Ron Van Bruchem',
		competiotn: "Dutch Championship 2007",



	  },

	{
		date: '2008-02-23',
		single: 9.18,
		name: 'Edouard Chambon',
		competiotn: "Murcia Open 2008",


	  },



	{
		date: '2008-05-05',
		single: 8.72,
		name: 'Yu Nakajima (中島悠)',
		competition: "Kashiwa Open 2008",


	  },

	{
		date: '2008-07-13',
		single: 7.08,
		name: 'Erik Akkersdijk',
		competition: "Czech Open 2008",


	  },

	  {
		date: '2010-11-13',
		single: 7.03,
		name: 'Feliks Zemdegs',
		competition: "Melbourne Cube Day 2010",


	  },
	  {
		date: '2010-11-13',
		single: 6.77,
		name: 'Feliks Zemdegs',
		competition: "Melbourne Cube Day 2010",


	  },

	  {
		date: '2011-01-30',
		single: 6.65,
		name: 'Feliks Zemdegs',
		competition: "Melbourne Summer Open 2011",


	  },

	  {
		date: '2011-05-07',
		single: 6.24,
		name: 'Feliks Zemdegs',
		competition: "Kubaroo Open 2011",


	  },



	  {
		date: '2011-06-25',
		single: 6.18,
		name: 'Feliks Zemdegs',
		competition: "	Melbourne Winter Open 2011",


	  },
	  {
		date: '2011-06-25',
		single: 5.66,
		name: 'Feliks Zemdegs',
		competition: "	Melbourne Winter Open 2011",


	  },




	  {
		date: '2013-03-03',
		single: 5.55,
		name: 'Mats Valk',
		competiotn: "Zonhoven Open 2013",


	  },

	  {
		date: '2015-04-25',
		single: 5.25,
		name: 'Collin Burns',
		competition: "Doylestown Spring 2015",



	  },

	  {
		date: '2015-11-21',
		single: 5.09,
		name: 'Keaton Ellis',
		competition: "River Hill Fall 2015",


	  },




	  {
		date: '2015-11-21',
		single: 4.90,
		name: 'Lucas Etter',
		competition: "River Hill Fall 2015",



	  },

	  {
		date: '2016-11-06',
		single: 4.74,
		name: 'Mats Valk',
		competition: "Jawa Timur Open 2016",


	  },


	  {
		date: '2016-12-11',
		single: 4.73,
		name: 'Feliks Zemdegs',
		competition: "POPS Open 2016"


	  },


	  {
		date: '2017-09-02',
		single: 4.69,
		name: 'Patrick Ponce',
		competition: "Rally In The Valley 2017",


	  },


	  {
		date: '2017-10-28',
		single: 4.59,
		name: 'SeungBeom Cho',
		competition: "ChicaGhosts 2017"


	  },


	  {
		date: '2018-05-06',
		single: 4.22,
		name: 'Feliks Zemdegs',
		competition: "Cube for Cambodia 2018"


	  },
	  {
		date: '2018-11-24',
		single: 3.47,
		name: 'Yusheng Du (杜宇生)',
		competition: "Wuhu Open 2018"


	  },
	  {
		date: '2023-06-11',
		single: 3.13,
		name: 'Max Park',
		competition: " Pride in Long Beach 2023"

	  },
	  {
		date: '2025-02-15',
		single: 3.08,
		name: 'Yiheng Wang (王艺衡)',
		competition: "XMUM Cube Open 2025"

	  },
  ];
  
  const CustomTooltip = ({ payload, label }) => {
	

	if (payload && payload.length) {

	  return (
		<div className="custom-tooltip">


        {payload.map((entry, index) => 

		{
			console.log(payload)
			const single = payload[0].name == "single";
			const dataAAAA = data.find(item => item.single ? item.single === entry.value : item.average === entry.value);
			const country = competitors[dataAAAA.name];
			const flag = flags[country];
          return <div key={index} style={{backgroundColor: single ?  "#3498db55" : "#00980055"}}>
            <p>{<b>{dataAAAA.name}</b> }: <b>{entry.value} sec</b></p>
			<div >
			<img src={flag} alt="Image" width="30"/> <span>{country}</span>
				  <p>{new Date(parseInt(label)).toDateString()}</p>
				  <p>{dataAAAA.competition}</p>



				</div>
          </div>
		  
        })}
			
		</div>
		      
	
	  );
	}
  
	return null;
  };



  // Convertir les dates en format timestamp
const formattedData = data.map(item => ({
	date: new Date(item.date).getTime(), // Convertir en timestamp
	single: item.single,
	average: item.average
  }));

const GraphWR = (() => {

	const formatDate = (date) => new Date(date);

  // Composant personnalisé pour afficher les drapeaux
  const CustomDot = (props) => {
	const { cx, cy, payload } = props;
  
	return (
	  <image
		x={cx - 10}   // Position horizontale du drapeau
		y={cy - 10}   // Position verticale du drapeau
		width="20"    // Largeur de l'image
		height="15"   // Hauteur de l'image
		href={payload.flag}  // Utilisation de l'URL du drapeau
	  />
	);
  };


  const [activeLines, setActiveLines] = useState({
    single: true,
    average: true,
  });

  const handleLegendClick = (e) => {
    const { dataKey } = e;
    setActiveLines((prevState) => ({
      ...prevState,
      [dataKey]: !prevState[dataKey],
    }));
  };

	  return  <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
		
		<AreaChart
      width={600}
      height={300}
      data={formattedData}
      margin={{
        top: 5,
        right: 0,
        left: 0,
        bottom: 5
      }}
    >
		 <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
		
dataKey="date"
type="number" // Utiliser 'number' pour que Recharts interprète les dates comme des valeurs numériques
scale="time"  // Indique que les valeurs sont des timestamps (dates)
domain={['auto', 'auto']}  //
tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}  // Formater les dates
		/>
        <YAxis />
		<Tooltip content={<CustomTooltip />} />
		<Legend onClick={handleLegendClick} />
        <Area hide={!activeLines.single} type="monotone" dataKey="single"  fill="#8884d8"  dot={true}/>
        <Area hide={!activeLines.average} type="monotone" dataKey="average"  fill="#00ff0022"  stroke = "green" dot={true}/>

	
      
	 
	 
	 
    </AreaChart>
	</ResponsiveContainer>
    </div>
  });

export default (() => {

	const { t, i18n } = useTranslation("rubik");

	const tLayer =  useTranslation("methodLayer");
	const tFridrich =  useTranslation("methodFridrich");




	  
	  

	const pairs = [
		["1974", t('invention')],
		["43,25 quintillions", t('combinations')],
		["20", t('moves')],
		["3.08 sec", t('wr')],
	  ];

	return <Box>
		<Menu idx={2} />
		<Banner title1="Rubik's Cube" title2={t('methods')}   page="rubik"/>

		<Container>

		

			<Box display="flex"> 
				<Paragraph  text='p001' t={t}/>
				
				<Button variant="contained" href="/rubikTeacher">Rubik Teacher</Button>
			</Box>




			<Typography variant="h2_software" sx={{m:3}}>{t('numbers')}</Typography>


			<Grid container spacing={2} justifyContent="center" direction="row">
				{pairs.map(([k,v]) => (
					<Grid item xs={12} sm={6} md={4} lg={3} key={k}>
					<Paper elevation={3}  
						style={{ 
							height: '150px', // Hauteur fixe
							display: 'flex', 
							alignItems: 'center', 
							justifyContent: 'center',
							textAlign: 'center' ,
							flexDirection: 'column',
						}}
					>
						<Typography variant="h4">{k}</Typography>
						<Typography>{v}</Typography>
					</Paper>
					</Grid>
				))}
			</Grid>


	



			<Typography variant="h2_software" sx={{m:3}}>{t('title2')}</Typography>



			<Paragraph text='desc' t={t} />

			{/*WR graph*/}
			<Typography variant="h2_software" sx={{m:3}}>{t('titleWR')}</Typography>

			<GraphWR />
			<p>single : résolution unique</p>
			<p>average: moyenne de 3 temps pertinents (sur 5 résolutions effectuées, en supprimant le meilleur et le pire chrono)</p>

			<Link href="/methodLayer" underline="none" >
					<Paper sx={{ m: 2,
									backgroundImage: 'linear-gradient(to right, #abebc6, #abebc633)',  // Couleur de fond
									padding:2,               // Padding pour l'intérieur
							
								}}>
					<Grid container spacing={2}>
						<Grid item  xs={12} sm={6} md={4} lg={3}>
							<img src="/res/rubik/layer/rubik_baby2.png" className="responsive-image" style={{  borderRadius:'20px' }}/>				
						</Grid>
						<Grid item xs={12} sm={6} md={8} lg={9}>
							<Typography variant="h1_software">{tLayer.t('level')}</Typography>
							<Typography variant="h2_software">{tLayer.t('title')}</Typography>
							<Paragraph  text='desc' t={tLayer.t} />
						</Grid>
					</Grid>
				</Paper>
			</Link>


			<Link href="/methodFridrich" underline="none">
			<Paper sx={{ m: 2,
									backgroundImage: 'linear-gradient(to left, #d7bde2, #d7bde233)',  // Couleur de fond
									padding:2,               // Padding pour l'intérieur
							
								}}>
				
					<Grid container spacing={2}>
						<Grid item  xs={12} sm={6} md={8} lg={9}>
							<Typography variant="h1_software">{tFridrich.t('level')}</Typography>
							<Typography variant="h2_software">{tFridrich.t('title')}</Typography>
							<Paragraph  text='desc' t={tFridrich.t} />
						</Grid>
						<Grid item  xs={12} sm={6} md={4} lg={3}>
							<img src="/res/rubik/fridrich/rubik_robot.png" className="responsive-image" style={{  borderRadius:'20px' }}/>				
						</Grid>
					</Grid>
				</Paper>
			</Link>
		</Container>
	</Box>
});
