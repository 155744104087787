import {React, useMemo} from "react";

import { Container, Typography, Box, Link, Grid, Button, Stack } from '@mui/material';



function BannerSoftware ({selected=null, backgroundColor=false, youtube=false, style=null}) {

	const MyButton = ({title, href, icon, activated=false}) => {
		return <Button variant={activated ? "contained" : "outlined"} 
		href={href} 
		startIcon={<img src={icon} style={{ width: 36, height: 36 }} />}
		sx={{ 
			fontFamily:'Verdana', 
			color:  'white', 
			fontWeight: activated ? 'bold' : 'normal' ,
			mr: 2
		}}>{title}</Button>

	}

return <Stack
spacing={1}
direction="row"
useFlexGap
sx={{ flexWrap: 'wrap' }}
>
<MyButton title="Tasky" href="/tasky" icon="/res/tasky/Tasky_ico.png" activated={style || selected=="tasky"} />
			<MyButton title="Rubik Teacher" href="/rubikTeacher" icon="/res/rubikTeacher/rubikTeacherIcon.png" activated={style ||selected=="rubikTeacher"} />
			<MyButton title="Virtual Stackmat Timer" href="/virtualStackmatTimer" icon="/res/stackmatTimer/stackmatTimerIcon.png" activated={style ||selected=="virtualStackmatTimer"} />

			{youtube && <Link href="https://www.youtube.com/@sebLabo/videos" sx={{ m: 1 }}>
				<img src="/youtube.png" width="120px"/>
			</Link>}
</Stack>
};

export { BannerSoftware };