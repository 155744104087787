import { React, useEffect } from "react";


import Menu from "menu";
import MenuAuto from "MenuAuto";

import Stack from "@mui/material/Stack"

import { BannerFridrich } from "./bannerFridrich";

import './methodFridrich.css';

import { Container, Typography, Box, Link, Grid,Grid2, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';

export default (() => {
	useEffect(() => {
    // Code JavaScript ici
    const script = document.createElement('script');
    script.src = "https://sebastienlabonne.dev/jquery.min.js";
    script.async = true;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.src = "https://sebastienlabonne.dev/roofpig_and_three.min.js";
    script2.async = true;
    document.head.appendChild(script2);

    // Cleanup si nécessaire (si le script est temporaire par exemple)
    return () => {
      document.head.removeChild(script);
		  document.head.removeChild(script2);
    };
  }, []); // Le tableau vide [] signifie que l'effet ne s'exécutera qu'une seule fois après le montage


  const { t, i18n } = useTranslation("methodFridrich");

	return <Box>

		<Menu idx="2" halo={false}/>

    <BannerFridrich idx={4} t={t}  />
    <MenuAuto content={document} titles={"h2"}/>

<Container>
	<Typography variant="h6">Méthode Fridrich - PLL - 1 Hand (Left)</Typography>
				
        				
	    <div>
        Mouvements optimisés pour la résolution à une main (gauche)
      </div>
	    <div>

        La probabilité de rencontrer chacun des cas est indiqué en haut à gauche de chaque case
      </div>


      <h2>
				Les coins sont en place
			</h2>

      <Grid2 container >
					<Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <div class="roofpig" data-config="alg=R U' R U R U R U' R' U' R2| flags=showalg" >1/18</div>
					</Grid2>
					<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg=z U' R U' R' U' R' U' R U R U2| flags=showalg" >1/18</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig b" data-config="alg= U R' U' R U' R U R U' R' U R U R2 U' R' U| flags=showalg">1/36</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}> 
          <div class="roofpig c" data-config="alg= R2 U2 R U2 R2 U2 R2 U2 R U2 R2| flags=showalg">1/72</div>
					</Grid2>
				</Grid2>



		
      <h2>
				Les arêtes sont en place
			</h2>
      <Grid2 container >
					<Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <div class="roofpig" data-config="alg=x R2 D2 R U R' D2 R U' R| flags=showalg">1/18</div>
					</Grid2>
					<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= x R' U R' D2 R U' R' D2 R2| flags=showalg">1/18</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig b" data-config="alg=x' R U' R' D R U R' D' R U R' D R U' R' u' | flags=showalg">1/36</div>
					</Grid2>
				</Grid2>



      <h2>
				2 coins adjacents en place 
			</h2>

      <Grid2 container >
					<Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <div class="roofpig" data-config="alg= R2 U R2 U' R2 U' D R2 U' R2 U R2 D'| flags=showalg">1/18</div>
					</Grid2>
					<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= R U R' U' R' U R U2 L' R' U R U' L U' R U' R'| flags=showalg">1/18</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= R U' R' U' R U R D R' U' R D' R' U2 R' U'| flags=showalg">1/18</div>
					</Grid2>
          <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <div class="roofpig" data-config="alg= R' U R U R' U' R' D' R U R' D R U2 R U| flags=showalg">1/18</div>
					</Grid2>
					<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= R' U L' U2 R U' R' U2 R L U'| flags=showalg">1/18</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= z U' R D R' U R2 D' R z' R U2 R'| flags=showalg">1/18</div>
					</Grid2>
				</Grid2>




      <h2>
				2 coins opposés en place
			</h2>
      <Grid2 container >
					<Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <div class="roofpig" data-config="alg= z U' R' U R D2 R' U' R U R2 z' R2 U' R2 U' R2 U'| flags=showalg">1/18</div>
					</Grid2>
					<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= R U' z U' R D' R' D R' U R D' R2 U' R2 U| flags=showalg">1/18</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig c" data-config="alg= R' z R U' R2 D R' U D' R U' R2 D R' U R'| flags=showalg">1/72</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig c" data-config="alg= z U R' D R2 U' R D' U R' D R2 U' R D' R| flags=showalg">1/72</div>
					</Grid2>
				</Grid2>






      <h2>
				1 coin et une arête adjacente en place
			</h2>
      <Grid2 container >
					<Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
          <div class="roofpig" data-config="alg= R U R' z x U2 r' U R' U' R U' r U2 | flags=showalg">1/18</div>
					</Grid2>
					<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= R2 u' R U' R U R' D x' U2 r U' r'| flags=showalg">1/18</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= z U' R' U x U2 r U' R U R' U r' U2 | flags=showalg">1/18</div>
					</Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }} >
          <div class="roofpig" data-config="alg= R2 u R' U R' U' R u' R2 z x U' R U | flags=showalg">1/18</div>
					</Grid2>
				</Grid2>




	</Container>

  </Box>

});
