import React from "react";
import { useRef } from 'react';

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link, Stack, Item} from '@mui/material';

import { useTranslation } from 'react-i18next';

import {Paragraph } from "toolbox"


import { BannerSoftware } from "Software/bannerSoftware";

//import Spline from '@splinetool/react-spline';

//import "./home.css"

const BannerRubik = () => {

	const t1 = useTranslation("methodLayer");
	const tFridrich = useTranslation("methodFridrich");

	return <Stack
	spacing={1}
	direction="row"
	useFlexGap
	sx={{ flexWrap: 'wrap' }}
  >
		<Button variant="contained" href="/methodLayer" sx={{ m: 1 }}>{t1.t('title')}</Button>
		<Button variant="contained" href="/methodFridrich" sx={{ m: 1 }}>{tFridrich.t('title')}</Button>
  </Stack>
}



export default (() => {

	const { t, i18n } = useTranslation("home");

	






	return <Box>
		<Menu idx={0}/>
		<Banner title1={t('title')} title2="Sébastien LABONNE"  page="home" youtube={true}/>

	
		

		<Container>

			<Paragraph  text='desc' t={t} />

			<Paper sx={{ m: 2,
				backgroundImage: 'linear-gradient(to right, #7fb3d5, #7fb3d500)',  // Couleur de fond
				padding: '20px',               // Padding pour l'intérieur
				borderRadius: '8px'            // Optionnel, pour arrondir les coins
			}}>
				<Link href="/software" sx={{ textDecoration: 'none' }}>
					<Grid container spacing={2}>
					<Grid xs={6} sm={2} md={2} lg={2}>
							<img  src="/res/software/software.png" className="responsive-image"/>
						</Grid>
					<Grid item xs={12} sm={10} md={10} lg={10}>
						<Typography variant="h2_home">{t('software')}</Typography>
						<Typography>{t('software_desc')}</Typography>
						<BannerSoftware style={1}/>
					</Grid>
					</Grid>
				</Link>
			</Paper>


			<Paper sx={{ m: 2,
		
        backgroundImage: 'linear-gradient(to left, #f7dc6f, #f7dc6f00)',  // Couleur de fond
        padding: '20px',               // Padding pour l'intérieur
        borderRadius: '8px'            // Optionnel, pour arrondir les coins
      }}>
				<Link href="/rubik" sx={{ textDecoration: 'none' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={10} md={10} lg={10} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end',  paddingRight: '40px'}}>
							<Typography variant="h2_home">{t('rubik')}</Typography>
							<Typography>{t('rubik_desc')}</Typography>
							<BannerRubik t={t}/>
						</Grid>
						<Grid xs={6} sm={2} md={2} lg={2}>
							<img  src="/res/rubik/rubik.png" className="responsive-image"/>
						</Grid>
					</Grid>
				</Link>
			</Paper>

			<Paper sx={{ m: 2,
	
        backgroundImage: 'linear-gradient(to right, #eb984e, #eb984e00)',  // Couleur de fond
        padding: '20px',               // Padding pour l'intérieur
        borderRadius: '8px'            // Optionnel, pour arrondir les coins
      }}>
				<Link href="/piano" sx={{ textDecoration: 'none' }}>
				<Grid container spacing={2}>
					<Grid xs={6} sm={2} md={2} lg={2}>
						<img  src="/res/piano/piano2.png" className="responsive-image"/>
					</Grid>
					<Grid item xs={12} sm={10} md={10} lg={10}>
						<Typography variant="h2_home">{t('piano')}</Typography>
						<Typography >{t('piano_desc')}</Typography>
					</Grid>
				</Grid>
				</Link>
			</Paper>




		</Container>
	</Box>
});