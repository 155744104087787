export default 
{
  title: "Bienvenue sur mon site Web",
  desc: `Vous trouverez sur ce site:`,
  software: "Mes logiciels",
  software_desc: "Les logiciels persos que je développe principalement en C++/Qt",
  rubik: "Résolution du Rubik's Cube",
  rubik_desc: "Apprenez à résoudre le Rubik's Cube selon votre niveau",
  piano: "Piano",
  piano_desc: "Apprenez le solfège",



  menu : {
    home : "Accueil",
    software: "Mes logiciels",
    rubik: "Rubik's Cube",
    piano: "Piano",
    links: "Liens",
    about: "A propos..."
  },


  form: {
    text: `Une idée d'amélioration ?
    Un bug ?
    Un encouragement ?`,
    send: 'Envoyer',
    placeholder: 'Votre message'
  }
};