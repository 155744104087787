import React from "react";

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link } from '@mui/material';

import { useTranslation } from 'react-i18next';

const Section = ({title, icon, url, size='10%'}) => {
	return (	
	<Link href={url} underline="none" m={2}>
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center', // Aligne verticalement au centre
				justifyContent: 'flex-start'
			}}
			>
			<Box
				sx={{
				width: { xs: size, sm: size, md: size }, // Ajuste la taille de l'icône en %
				height: 'auto', // Garde les proportions
				}}
			>
						<img src={icon}  style={{ width: '100%', height: 'auto' }} />
			</Box>
			<Typography
				variant="body1"
				sx={{ marginLeft: 1 }} // Espace entre l'icône et le texte
			>
			{title}
			</Typography>
		</Box>
	</Link>)
}









export default (() => {
	
	const { t, i18n } = useTranslation("about");

	return <Box>
		<Menu idx={5} />
		<Banner title1={t('title')} title2="Sébastien LABONNE" title3="Toulouse (31) - France" image="/res/about/profile.jpg" page="about"/>

	
		<Container >
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>

					<Paper style={{ padding: '20px' }}>
						<Typography variant="h4">Contact</Typography>

						<Section title="Email" icon="/res/about/email.png" url="mailto:sebastien.labonne@gmail.com" />
						<Section title="LinkedIn" icon="/res/about/linkedin.png" url="https://www.linkedin.com/in/slabonne/" size='8%'/>
					</Paper>
				</Grid>
		


				<Grid item xs={12} md={4}>
					<Paper style={{ padding: '20px' }}>
						<Typography variant="h4">{t('about')}</Typography>

						<Section title={t('cv')} icon="/res/about/cv.avif" url="/CV" size='20%'/>
						<Section title={t('rubikResults')} icon="/res/rubik/rubik.png" url="https://www.worldcubeassociation.org/persons/2013LABO01" size='15%' />
					</Paper>
				</Grid>	
			</Grid>
		</Container>

	</Box>
});