import {React, useEffect} from "react";

import Menu from "menu";
import MenuAuto from "MenuAuto";



import { Container, Typography, Box, Link, Grid2, Button , Stack} from '@mui/material';

import { BannerFridrich } from "./bannerFridrich";



import { useTranslation } from 'react-i18next';

export default (() => {

	useEffect(() => {
        // Code JavaScript ici
        const script = document.createElement('script');
        script.src = "https://sebastienlabonne.dev/jquery.min.js";
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://sebastienlabonne.dev/roofpig_and_three.min.js";
        script2.async = true;
        document.head.appendChild(script2);
    
        // Cleanup si nécessaire (si le script est temporaire par exemple)
        return () => {
          document.head.removeChild(script);
		  document.head.removeChild(script2);
        };
      }, []); // Le tableau vide [] signifie que l'effet ne s'exécutera qu'une seule fois après le montage
  
	  const { t, i18n } = useTranslation("methodFridrich");

	return <Box>
	
	
		<Menu idx={2} halo={false}/>


		<BannerFridrich idx={1} t={t}/>
		<MenuAuto content={document} titles='h2'/>

			<Container>

		<Typography variant="h6">Méthode Fridrich - OLL</Typography>
				
        <div>
	
		

<h2>Plus</h2>
	<Grid2 container spacing={2} id="plus">
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U' L' U R' U' L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' U R U' L U R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R' U' R U R' U' R U' R'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>	
		</Grid2>

		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U2 R2 U' R2 U' R2 U2 R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L2 D' L U2 L' D L U2 L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F' L F R F' L' F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F' L' F R y' R' F R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
	</Grid2>	




<h2>T shape</h2>
	<Grid2 container spacing={2} id="t-shape">
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U' F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' R' F R F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
	</Grid2>




<h2>P shape</h2>
	<Grid2 container spacing={2} id="p-shape">
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L d R' d' L' U L F L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' d' L d R U' R' F' R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>

		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L U y' R U' R' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>	
		</Grid2>
		<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' y L' U L F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
		</Grid2>
	</Grid2>






<h2>C shape</h2>
<Grid2 container spacing={2} id="c-shape">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U x' R U' R' U x U' R'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' x D' R' U R E' z'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>



<h2>S shape</h2>
<Grid2 container spacing={2} id="s-shape">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R U R' U' y L' d R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F' L' U' L U y' R d' L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>




<h2>W shape</h2>
<Grid2 container spacing={2} id="-shape">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L U L' U L U' L' U' L' B L B' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' R U' R' U R U R B' R' B | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>




<h2>I shape</h2>
<Grid2 container spacing={2} id="i-shape">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' y' R' U R U' R' U R x' U x| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' y L' U L' y' L F L' F R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>	
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=L' B' L U' R' U R U' R' U R L' B L| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=R U' y R2 D R' U2 R D' R2 d R'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
	</Grid2>
</Grid2>




<h2>Big L shape</h2>
<Grid2 container spacing={2} id="big-l-shape">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R U R' F' R y L U' L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F' L' U' L F L' y' R' U R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R B R' L U L' U' R B' R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' B' L R' U' R U L' B L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>



<h2>Little L shape</h2>
<Grid2 container spacing={2} id="little-l-shape">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U' R U R' U' F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F' L' U' L U L' U' L U F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L U' y' R' U2 R' U R U' R U2 R d' L' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R' F' R2 U2 x' U' R U R' x | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>

	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' F R F' U2 R2 y R' F' R F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F' L' F U2 L2 y' L F L' F | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>




<h2>Squares</h2>
<Grid2 container spacing={2} id="squares">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r U2 R' U' R U' r'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=r' U2 R U R' U r | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>

</Grid2>












<h2>Fishes</h2>
<Grid2 container spacing={2} id="fishes">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U2 l R U' R' U l' U2 R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=x' R U' R' U y' R U R' U'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U' R y' x' R U' R' F R U R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' y R' F R U' R' F' R | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>




<h2>Lightning</h2>
<Grid2 container spacing={2} id="lightning">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L F R' F R F2 L'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=L' B' L U' R' U R L' B L| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U2 r R2 U' R U' R' U2 R U' M | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U2 l' L2 U L' U L U2 L' U M | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>



<h2>H shape</h2>
<Grid2 container spacing={2} id="h-shape">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=x' U' R U' R2 F x R U R' U' R B2| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R2 U R' B' R U' R2 U R B R'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U' R U2 R' U' R U' R2 y' R' U' R U B | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=U' R' U2 R U R' U R2 y R U R' U' F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
</Grid2>






<h2>Corners OK</h2>
<Grid2 container spacing={2} id="corners-ok">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=M' U' M U2 M' U' M | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=L' R U R' U' L R' F R F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig c" data-config="alg=M' U2 M U2 M' U M U2 M' U2 M| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/216</div>
	</Grid2>
</Grid2>




<h2>Points</h2>
<Grid2 container spacing={2} id="points">
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=y L' R2 B R' B L U2' L' B M' x' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U2 x R' U R U' y R' U' R' U R' F z' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R' U2 F R U R' U' y' R2 U2 x' R U| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=F R U R' U y' R' U2 x R' U R U' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>

	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U R' F R F' U2 R' F R F' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/54</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>

	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=R U B' l U l2' x' U' R' F R F'| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
	</Grid2>
	<Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig b" data-config="alg=R' F R F' U2 R' F R y' R2 U2 R| flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black">1/108</div>
	</Grid2>
</Grid2>






</div>
 





	</Container>
	</Box>;
});
