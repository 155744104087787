export default 
{
  title: "Learn solfeggio",
  rules: `Learn solfeggio for piano through this game. 
  The goal is to guess as many notes as possible under 1 minute.`,
  start: 'Start',
  levelChoice: "Choose a learning level",

    level1: 'White keys / C Clef',
    level2: '+ Black keys',
    level3: '+ F Clef',
    level4: '+ Key Signature',
    level5: 'Perfect Pitch',
    level99: 'Training',

    scales: "Scales",
    all: "All",
};